var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"transparent","flat":"","dark":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticClass:"text-h4 font-weight-bold",staticStyle:{"padding":"5vh"}},[_vm._v("Booking Management")])]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{attrs:{"width":"90%","flat":"","color":"transparent"}},[_c('v-btn',{staticClass:"black--text",staticStyle:{"float":"right"},attrs:{"depressed":"","color":"white"},on:{"click":function($event){return _vm.SET_IMPORT_BOOKING_DIALOG_VISIBILITY(true)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-application-import")]),_vm._v(" Import ")],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"pa-2",attrs:{"rounded":"lg","width":"90%"}},[_c('v-card-subtitle',{staticStyle:{"display":"inline-block"}},[_vm._v("Booking List (Total #"+_vm._s(_vm.total_booking_record)+" Records)")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-n2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"grey","hide-details":"","label":"Date Range","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_rage),callback:function ($$v) {_vm.date_rage=$$v},expression:"date_rage"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_picker.visibility),callback:function ($$v) {_vm.$set(_vm.date_picker, "visibility", $$v)},expression:"date_picker.visibility"}},[_c('v-date-picker',{ref:"picker",attrs:{"color":"#2fcfa6","landscape":"","range":""},on:{"change":_vm.getBooking},model:{value:(_vm.date_picker.date),callback:function ($$v) {_vm.$set(_vm.date_picker, "date", $$v)},expression:"date_picker.date"}})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"color":"grey","hide-details":"","label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"hide-default-footer":"","headers":_vm.bookings.headers,"items":_vm.bookings.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookings.items.indexOf(item) + 1)+" ")]}},{key:"item.import_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.create_datetime).format('YYYY-MM-DD hh:mm:ss'))+" ")]}},{key:"item.booking_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date)+" "+_vm._s(item.time)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","icon":"","small":""},on:{"click":function($event){return _vm.deleteBooking(item.booking_id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)],1),_c('importBookingDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }