<template>
  <v-container fluid>
    <!-- Title -->
    <v-row>
      <v-col cols="12">
        <v-card color="transparent" flat dark>
          <v-row justify="center">
            <div class="text-h4 font-weight-bold" style="padding: 5vh">Booking Management</div>
          </v-row>
          <v-row align="center" justify="center">
            <v-card width="90%" flat color="transparent">
              <v-btn style="float: right" depressed color="white" class="black--text" @click="SET_IMPORT_BOOKING_DIALOG_VISIBILITY(true)">
                <v-icon left>mdi-application-import</v-icon>
                Import
              </v-btn>
            </v-card>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Body -->
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-card rounded="lg" width="90%" class="pa-2">
            <v-card-subtitle style="display: inline-block">Booking List (Total #{{ total_booking_record }} Records)</v-card-subtitle>
            <v-card-text>
              <v-row no-gutters class="mt-n2">
                <v-col cols="3">
                  <v-menu ref="menu" v-model="date_picker.visibility" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="grey" hide-details v-model="date_rage" label="Date Range" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker @change="getBooking" color="#2fcfa6" landscape ref="picker" v-model="date_picker.date" range></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>

                <v-col cols="4">
                  <v-text-field color="grey" hide-details v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>
                </v-col>
              </v-row>
              <v-data-table class="mt-4" hide-default-footer :headers="bookings.headers" :items="bookings.items" :search="search">
                <!-- Index -->
                <template v-slot:[`item.index`]="{ item }">
                  {{ bookings.items.indexOf(item) + 1 }}
                </template>

                <!-- Import Datetime -->
                <template v-slot:[`item.import_datetime`]="{ item }">
                  {{ $moment(item.create_datetime).format('YYYY-MM-DD hh:mm:ss') }}
                </template>

                <!-- Booking Datetime -->
                <template v-slot:[`item.booking_datetime`]="{ item }"> {{ item.date }} {{ item.time }} </template>

                <!-- Action -->
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn color="error" icon small @click="deleteBooking(item.booking_id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>

    <importBookingDialog />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import importBookingDialog from '@/components/dialogs/importBookingDialog'

export default {
  name: 'Bookings',
  components: { importBookingDialog },
  data() {
    return {
      search: null,
      date_picker: {
        visibility: false,
        date: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      }
    }
  },
  methods: {
    ...mapActions(['getBookingRecordsByDateRange', 'deleteBookingRecordByID']),
    ...mapMutations(['SET_IMPORT_BOOKING_DIALOG_VISIBILITY', 'SET_BOOKING_SELECTED_DATE_RANGE']),
    getBooking() {
      this.getBookingRecordsByDateRange({
        from_date: this.date_picker.date[0],
        to_date: this.date_picker.date[1]
      })
    },
    deleteBooking(booking_id) {
      this.deleteBookingRecordByID({
        params: {
          booking_id: booking_id
        }
      })
    }
  },
  computed: {
    ...mapState(['bookings']),
    date_rage() {
      if (this.date_picker.date) return this.date_picker.date.join(' ~ ')
      else {
        return null
      }
    },
    total_booking_record() {
      return this.bookings.items.length || ''
    }
  },
  watch: {
    'date_picker.visibility': {
      immediate: true,
      deep: true,
      handler() {
        if (this.date_picker.visibility) {
          this.date_picker.date = null
        }
      }
    },
    'date_picker.date': {
      immediate: true,
      deep: true,
      handler() {
        // Auto close the date picker if user has selected two date
        this.SET_BOOKING_SELECTED_DATE_RANGE(this.date_picker.date)
        if (this.date_picker.date && this.date_picker.date.length === 2) {
          this.date_picker.visibility = false
        }
      }
    }
  },
  mounted() {
    this.getBooking()
  }
}
</script>
