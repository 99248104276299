<template>
  <div class="text-center">
    <v-dialog v-model="importBookingDialog.visibility" max-width="500" persistent>
      <v-card class="pa-0" flat>
        <!-- Header -->
        <v-toolbar dark color="rgb(47, 207, 156)" dense>
          <v-btn icon disabled></v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>Import Booking</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="SET_IMPORT_BOOKING_DIALOG_VISIBILITY(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-row no-gutters class="pt-6 py-4 px-4" justify="center">
          <v-col cols="12">
            <v-file-input
              color="grey"
              dense
              show-size
              label="Booking Record"
              v-model="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-btn block depressed @click="importRecord">Import</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'importBookingDialog',
  data() {
    return {
      file: null
    }
  },
  computed: { ...mapState(['importBookingDialog']) },
  methods: {
    ...mapMutations(['SET_IMPORT_BOOKING_DIALOG_VISIBILITY', 'SET_SNACKBAR']),
    ...mapActions(['importBookingRecords']),
    importRecord() {
      if (this.file) {
        const formData = new FormData()
        formData.append('csv', this.file)
        this.importBookingRecords({
          params: formData,
          headers: {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        })
      } else {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please select the file that you want to import.', icon: 'close', timeout: '2000' })
      }
    }
  }
}
</script>
